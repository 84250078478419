import {Link, useLocation, useNavigate} from "react-router-dom";
import { useForm } from "react-hook-form";
import {Loader} from "../../components/Loader";
import { useState} from "react";
import useAuth from "../../hooks/useAuth";
import axios from "../../api/axios";
import { SIGN_UP} from "../../helpers/api-routes/v2";
import {toast} from "react-toastify";


export default function Signup() {

    const [loading, setLoading] = useState(false);

    const { setAuth } = useAuth();

    let navigate = useNavigate();

    const location = useLocation();

    const from = location.state?.from?.pathname || "/app/home";

    const { register, handleSubmit, formState: { errors } } = useForm();
    const onSubmit = async ({name, email, password, phone}) => {
        setLoading(true);

        const payload = {name, email, password, phone, deviceId: 'web' };
        try {
            const response = await axios.post(SIGN_UP,
                JSON.stringify(payload),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                }
            );

            setLoading(false);

            const result = response?.data?.success.data;

            const accessToken = result?.accessToken;

            const user = result?.user;

            setAuth({ user, accessToken, subscription: result?.subscription });

            navigate(from, { replace: true });

        } catch (err) {
            setLoading(false)
            toast.error(err?.response?.data.error.message);
        }
    };

    return (
        <div className={`relative flex h-screen w-screen flex-col sm:bg-black/20 bg-black md:items-center md:justify-center md: bg-transparent`}>

            <div className={"w-full h-full bg-no-repeat bg-cover bg-left bg-fixed"}
                 style={{
                     backgroundImage: `url("https://res.cloudinary.com/northflix/image/upload/v1650898997/avatar/bg_1_dwoxjs.jpg")`
                 }}>
                <img
                    src="https://res.cloudinary.com/northflix/image/upload/v1650827688/avatar/northflix_white_logo_1024_x_227_efp5f7.svg"
                    className="absolute left-4 top-4 cursor-pointer object-contain md:left-10 md:top-6"
                    width={150}
                    height={150}
                    alt={'logo'}
                />
                <div className={"flex justify-center items-center md:mt-[120px]"}>
                    <form onSubmit={handleSubmit(onSubmit)} className={'relative mt-24 space-y-8 rounded bg-black/75 py-10 px-6 md:mt-0 md:max-w-md md:px-14'}>
                        <h1 className={"text-4xl font-semibold"}>Sign up</h1>
                        <div className={"space-y-4"}>

                            <label className={"inline-block w-full"}>
                                <input
                                    type={'text'}
                                    placeholder={'Fullname'}
                                    className={'input'}
                                    {...register("name",{required: true})}
                                />
                                {/* errors will return when field validation fails  */}
                                {errors.name && (
                                    <p className="p-1 text-[13px] font-light  text-orange-500">
                                        Please enter a valid name.
                                    </p>
                                )}
                            </label>

                            <label className={"inline-block w-full"}>
                                <input
                                    type={'email'}
                                    placeholder={'Email'}
                                    className={'input'}
                                    {...register("email",{required: true})}
                                />
                                {/* errors will return when field validation fails  */}
                                {errors.email && (
                                    <p className="p-1 text-[13px] font-light  text-orange-500">
                                        Please enter a valid email.
                                    </p>
                                )}
                            </label>

                            <label className={"inline-block w-full"}>
                                <input
                                    type={'number'}
                                    placeholder={'Phone Number'}
                                    className={'input'}
                                    {...register("phone",{required: true, maxLength: 11})}
                                />
                                {/* errors will return when field validation fails  */}
                                {errors.phone && (
                                    <p className="p-1 text-[13px] font-light  text-orange-500">
                                        Please enter a valid phone number.
                                    </p>
                                )}
                            </label>

                            <label className={"inline-block w-full"}>
                                <input
                                    type={'password'}
                                    placeholder={'Password'}
                                    className={'input'}
                                    {...register("password", {required: true})}
                                />
                                {errors.password && (
                                    <p className="p-1 text-[13px] font-light  text-orange-500">
                                        Your password must contain between 6 and 60 characters.
                                    </p>
                                )}
                            </label>
                        </div>

                        <button
                            type={'submit'}
                            className={`w-full rounded bg-yellow-500 py-3 font-semibold ${
                                loading && 'opacity-60'
                            }`}
                        >
                            {loading ? (
                                <Loader color="dark:fill-gray-300" />
                            ) : (
                                'Sign up'
                            )}
                        </button>

                        <div className={"text-[gray]"}>
                            Already have a Northflix account? {' '}
                            <Link to={'/auth/login'}>
                                <span className={"hover:underline text-white"}>Sign in</span>
                            </Link>
                        </div>
                    </form>
                </div>
            </div>
        </div>

    )
}