import React, {useState} from "react";
import {useForm} from "react-hook-form";
import {Loader} from "./Loader";
import {toast} from "react-toastify";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import {UPDATE_PASSWORD} from "../helpers/api-routes/v2";

const UpdatePassword = () => {

    const [loading, setLoading] = useState(false);

    const axiosPrivate = useAxiosPrivate();

    const { register, handleSubmit, formState: { errors } } = useForm();

    const onSubmit = async ({password, confirmPassword}) => {

        if (password !== confirmPassword) return toast.error('Password does not match confirm password')

        const payload = { password, repeat_password:confirmPassword };

        setLoading(true);

        const response = await axiosPrivate.post(UPDATE_PASSWORD, payload);

        if (response.status === 200) {
            setLoading(false);
            return toast.success('Password changed successfully');
        }
        else
        {
            setLoading(false);
            return toast.error(response.data.error.message);
        }
    }

    return (
        <>
            <h2 className={"font-bold text-4xl"}>My Subscription</h2>
            <div className={"mt-[80px] flex flex-col justify-center"}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <label className={"inline-block w-full"}>
                        <input
                            type={'password'}
                            placeholder={'Password'}
                            className={'input'}
                            {...register("password", {required: true})}
                        />
                        {errors.password && (
                            <p className="p-1 text-[13px] font-light  text-orange-500">
                                Your password must contain between 6 and 60 characters.
                            </p>
                        )}
                    </label>

                    <label className={"inline-block w-full mt-6"}>
                        <input
                            type={'password'}
                            placeholder={'Confirm Password'}
                            className={'input'}
                            {...register("confirmPassword", {required: true})}
                        />
                        {errors.confirmPassword && (
                            <p className="p-1 text-[13px] font-light  text-orange-500">
                                Your password must contain between 6 and 60 characters.
                            </p>
                        )}
                    </label>

                    <button
                        className={`w-full rounded bg-yellow-500 py-3 font-semibold mt-6 ${
                            loading && 'opacity-60'
                        }`}
                    >
                        {loading ? (
                            <Loader color="dark:fill-gray-300" />
                        ) : (
                            'Change Password'
                        )}
                    </button>
                </form>
            </div>
        </>
    )
}

export default UpdatePassword