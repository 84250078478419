import { useEffect} from "react";
import {Outlet} from "react-router-dom";
import useAuth from "../hooks/useAuth";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import {GET_BLOCK_IMAGES, GET_CATEGORIES, GET_MOVIES} from "../helpers/api-routes/v2";


const GetMovies = () => {

    const { setMovieCategories, setMovies, setIsLoading, setBlockImages } = useAuth();

    const axiosPrivate = useAxiosPrivate();

    useEffect(() => {

        let isMounted = true;

        const controller = new AbortController();

        const getBlockImages = async () => {
            const response = await axiosPrivate.get(GET_BLOCK_IMAGES, {
                signal: controller.signal
            })
            const result = response.data.success.data;
            setBlockImages(result)
        }

        const getCategories = async () => {
            const response = await axiosPrivate.get(GET_CATEGORIES, {
                signal: controller.signal
            })
            isMounted && setMovieCategories(response.data.success.data);
        }

        const getMovies = async () => {
            const response = await axiosPrivate.get(GET_MOVIES, {
                signal: controller.signal
            });
            // console.log(response.data.success.data);
            isMounted && setMovies(response.data.success.data);
            setIsLoading(false);
        }

        getBlockImages();
        getCategories();
        getMovies();

        return () => {
            isMounted = false;
            controller.abort();
        }

    },[axiosPrivate, setMovieCategories, setMovies, setIsLoading, setBlockImages]);

    return (
        <Outlet />
    )
}

export default GetMovies;