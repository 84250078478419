import MuiModal from '@mui/material/Modal';
import { XIcon } from "@heroicons/react/outline";
import ReactPlayer from "react-player";

const Modal = ({showModal, setShowModal, streamType, trailerUri}) => {

    const handleClose = () => {
        setShowModal(false);
    };

    return (
        <MuiModal
            open={showModal}
            onClose={handleClose}
            className="fixed !top-7 left-0 right-0 mx-auto w-full max-w-5xl overflow-hidden overflow-y-scroll rounded-md scrollbar-hide"
        >
            <>
                <button
                    className="modalButton absolute right-5 top-5 !z-40 h-9 w-9 border-none bg-[#181818] hover:bg-[#181818]"
                    onClick={handleClose}
                >
                    <XIcon className="h-6 w-6" />
                </button>

                <div className="relative pt-[56.25%]">

                    {
                        streamType === 'movie' ?
                            <div className={"flex justify-center top-20 space-x-4 mt-[-30%]"}>
                                <a href={"https://play.google.com/store/apps/details?id=com.Northflix.app"} target={"_blank"} rel={"noreferrer"} className={"rounded bg-white w-[150px]"}>
                                    <img
                                        src={"https://res.cloudinary.com/northflix/image/upload/v1659696871/plan/222-2228956_app-store-logo-app-store-icon-white_oqnpes.png"}
                                        alt={"apple"}
                                    />
                                </a>

                                <a href={"https://play.google.com/store/apps/details?id=com.Northflix.app"} target={"_blank"} rel={"noreferrer"} className={"rounded bg-white w-[150px]"}>
                                    <img
                                        src={"https://res.cloudinary.com/northflix/image/upload/v1659697224/plan/203-2031321_app-store-icon-play-store-icon-google-logo_nwoiez.png"}
                                        alt={"google"}
                                        className={"w-[150px]"}
                                    />
                                </a>
                            </div>
                            :
                            <ReactPlayer
                                config={{
                                    file: {
                                        attributes: {
                                            controlsList: 'nodownload',
                                        }
                                    }
                                }}
                                url={`https://www.youtube.com/watch?v=${trailerUri}`}
                                width="100%"
                                height="100%"
                                style={{ position: 'absolute', top: '0', left: '0' }}
                                playing
                                controls
                            />
                    }
                </div>

                <div className="space-x-16 rounded-b-md bg-[#181818] px-10 py-8">
                    <div className="space-y-6 text-lg">
                        <div className="flex items-center space-x-2 text-sm">
                            <p className="font-light">
                                Premium
                            </p>
                            <div className="flex h-4 items-center justify-center rounded border border-white/40 px-1.5 text-xs">
                                HD
                            </div>
                        </div>
                        <div className="flex flex-col gap-x-10 gap-y-4 font-light md:flex-row">
                            <p className="w-5/6">
                                {/*A group of vigilantes known informally as “The Boys” set out to take down corrupt superheroes with no more than blue-collar grit and a willingness to fight dirty.*/}
                            </p>
                            <div className="flex flex-col space-y-3 text-sm">
                                <div>
                                    <span className="text-[gray]">Genres:</span>{' '}
                                    {/*Sci-Fi & Fantasy, Action & Adventure, Crime & Mystery*/}
                                </div>

                                <div>
                                    <span className="text-[gray]">Original language:</span>{' '}
                                    Hausa & English
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        </MuiModal>
    );
}

export default Modal;
