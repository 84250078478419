import {Fragment} from "react";
import Header from "../../components/Header";
import MovieCard from "../../components/MovieCard";
import {useParams} from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import AppLoader from "../../components/AppLoader";


const Details = () => {

    const { isLoading } = useAuth();

    let params = useParams()

    const movieId = params.id;

    return (
        <Fragment>
            { isLoading ? <AppLoader />
                :
                <>
                    <Header />
                    <section className={'md:space-y-20'}>
                        <MovieCard movieId={movieId} />
                    </section>
                    {/*<Footer />*/}
                </>
            }

        </Fragment>
    )
}

export default Details;