import { createContext, useState } from "react";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
    const [auth, setAuth] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [moviesCategories, setMovieCategories] = useState([]);
    const [blockImages, setBlockImages] = useState([]);
    const [movies, setMovies] = useState([]);
    const [persist, setPersist] = useState(JSON.parse(localStorage.getItem("persist")) || false);

    return (
        <AuthContext.Provider value={{
            auth,
            setAuth,
            persist,
            setPersist,
            moviesCategories,
            setMovieCategories,
            movies,
            setMovies,
            isLoading,
            setIsLoading,
            blockImages,
            setBlockImages
        }}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext;