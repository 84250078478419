import MenuIcon from "@mui/icons-material/Menu";
import {Link} from "react-router-dom";
import useAuth from "../hooks/useAuth";
import { useState } from "react";
import CloseIcon from '@mui/icons-material/Close';

const MobileMenu = ({setFilteredCategory}) => {

    const { moviesCategories } = useAuth();

    const pathname = window.location.pathname

    const [current, setCurrent] = useState('home');

    const [isOpen, setIsOpen] = useState(false);

    const commandDisplay = () => {
        setIsOpen(!isOpen)
    }

    const changeCategory = (category) => {
        setIsOpen(false);
        setCurrent(category)
        if (category === 'home'){
            setFilteredCategory(moviesCategories);
        }else {
            setFilteredCategory(moviesCategories.filter(c => c.name === category))
        }
    }

    return (
        <div className="md:hidden block">
            {!isOpen ? <MenuIcon onClick={commandDisplay} /> : <CloseIcon onClick={commandDisplay} />}
            <div className={` ${isOpen ? 'flex' : 'hidden' } flex-col w-full bg-black/60 p-4 absolute z-10 justify-center items-center left-0 mt-2 right-2 transform ease-in duration-200 text-white`}>
                <Link onClick={() => changeCategory('home')} to={"/app/home"} className={`hover:bg-yellow-400 block w-full text-center p-2 cursor-pointer ${current === 'home' ? 'font-semibold' : ''}`}>
                    Home
                </Link>
                {
                    pathname === '/app/home' &&
                        moviesCategories.map((category) =>  (
                        <Link  onClick={() => changeCategory(category?.name)} to={"/app/home"} className={`hover:bg-yellow-400 block w-full text-center p-2 cursor-pointer ${current.toLowerCase() === category?.name.toLowerCase() ? 'font-semibold' : ''}`}>
                            {category?.name}
                        </Link>
                ))}
            </div>
        </div>
    )
}

export default MobileMenu;