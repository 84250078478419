import {Fragment} from "react";
import Header from "../../components/Header";
import SearchBar from "../../components/SearchBar";
import useAuth from "../../hooks/useAuth";
import AppLoader from "../../components/AppLoader";

const Search = () => {

    const { isLoading } = useAuth();

    return (
        <Fragment>
            {isLoading ? <AppLoader /> :
                <>
                    <Header />
                    <section className={'md:space-y-20'}>
                        <SearchBar />
                    </section>
                </>
            }
        </Fragment>
    )
}

export default Search;