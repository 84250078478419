import React from "react";
import {Skeleton} from "@mui/material";


const BannerSkeleton = () => {
    return (
        <div className="flex flex-col space-y-2 py-16 md:space-y-4 lg:h-[55vh] lg:justify-end lg:pb-12">
            <div className="absolute top-0 left-0 -z-10 h-[75vh] w-screen">
                <Skeleton variant="rectangular" width={'100%'} height={1440} sx={{ bgcolor: 'grey.900' }} />
            </div>
        </div>
    )
}

export default BannerSkeleton;