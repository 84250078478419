import {useState} from "react";
import Header from "../../components/Header";
import Banner from "../../components/Banner";
import Row from "../../components/Row";
import Footer from "../../components/Footer";
import useAuth from "../../hooks/useAuth";
import MovieSkeleton from "../../components/Skeleton/MovieSkeleton";
import BannerSkeleton from "../../components/Skeleton/BannerSkeleton";

const Home = () => {

    const { movies, moviesCategories, isLoading, blockImages } = useAuth();

    const [filteredCategory, setFilteredCategory] = useState([]);

    return (
        <div className={"overflow-hidden"}>
            <Header setFilteredCategory={setFilteredCategory} />
            <main className={"relative pl-4 pb-24 lg:space-y-24 lg:pl-16 space-y-36"}>
                {blockImages.length > 0 ? <Banner /> : <BannerSkeleton />}
                <section className={'md:space-y-10'}>
                    { isLoading ? <MovieSkeleton /> :
                        movies.length > 0 &&
                            filteredCategory.length > 0 ?
                                filteredCategory.map((category, index) => {
                                    const filtered = movies.filter((item) => item?.categoryId === category._id)
                                    if (filtered.length > 0)
                                    {
                                        return <Row key={index} category={category} movies={filtered} />
                                    }
                                })
                                :
                                moviesCategories.map((category, index) => {
                                    const filtered = movies.filter((item) => item?.categoryId === category._id)
                                    if (filtered.length > 0)
                                    {
                                        return <Row key={index} category={category} movies={filtered} />
                                    }
                                })
                    }
                </section>
            </main>
            <section className={'md:space-y-20'}>
                { filteredCategory.length > 1 &&
                    <Footer />
                }
            </section>
        </div>
    )
}

export default Home;