import {Skeleton} from "@mui/material";

function SkeletonThumbnail() {
    return (
        <div className={`relative cursor-pointer transition duration-200 ease-out md:hover:scale-105`}>
            <Skeleton variant="rectangular" width={150} height={225} sx={{ bgcolor: 'grey.900' }} />
        </div>
    )
}

export default SkeletonThumbnail
