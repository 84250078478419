import React from "react";
import useAuth from "../hooks/useAuth";
import moment from 'moment';

const MyProfile = () => {

    const { auth } = useAuth();

    return (
        <>
            <h2 className={"font-bold text-4xl"}>My Account</h2>
            <div className={"mt-[80px] flex flex-col justify-center"}>
                <div className={"flex items-center space-x-9"}>
                    <label className={"font-semibold text-2xl"}>Name: </label>
                    <label className={""}>{auth?.user.name}</label>
                </div>

                <div className={"flex items-center space-x-9 mt-[40px]"}>
                    <label className={"font-semibold text-2xl"}>Email: </label>
                    <label className={""}>{auth?.user.email}</label>
                </div>

                <div className={"flex items-center space-x-9 mt-[40px]"}>
                    <label className={"font-semibold text-2xl"}>Phone Number: </label>
                    <label className={""}>{auth?.user.phone}</label>
                </div>

                <div className={"flex items-center space-x-9 mt-[40px]"}>
                    <label className={"font-semibold text-2xl"}>Coupon Wallet: </label>
                    <label className={""}>NGN {auth?.user?.couponWallet}</label>
                </div>

                <div className={"flex items-center space-x-9 mt-[40px]"}>
                    <label className={"font-semibold text-2xl"}>Coupon ID: </label>
                    <label className={""}>NGN {auth?.user?.coupon === null ? 'No Coupon Code' : auth?.user?.coupon}</label>
                </div>

                <div className={"flex items-center space-x-9 mt-[40px]"}>
                    <label className={"font-semibold text-2xl"}>Account Status: </label>
                    <label className={""}>{auth?.user?.status}</label>
                </div>

                <div className={"flex items-center space-x-9 mt-[40px]"}>
                    <label className={"font-semibold text-2xl"}>Created On: </label>
                    <label className={""}>{moment(auth?.user?.createdAt).format('Do MMMM YYYY')}</label>
                </div>


            </div>
        </>
    )
}

export default MyProfile;