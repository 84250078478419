import SkeletonRow from "./SkeletonRow";


const MovieSkeleton = () => {

    const rows = [1,2,3,4,5];

    return rows.map((row) => ( <SkeletonRow key={row} />))
}

export default MovieSkeleton;