import {toCurrency} from "../helpers/toCurrency";
import moment from "moment";
import DataTable from 'react-data-table-component';
import React, {useEffect, useState} from "react";
import {GET_PAYMENTS} from "../helpers/api-routes/v2";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import {Loader} from "./Loader";


const MyPayments = () => {

    const [processing, setProcessing] = useState(false);

    const [transactions, setTransactions] = useState([]);

    const axiosPrivate = useAxiosPrivate();

    useEffect(() => {

        let isMounted = true;

        const controller = new AbortController();

        const getPayments = async () => {
            setProcessing(true);
            const response = await axiosPrivate.get(GET_PAYMENTS, {
                signal: controller.signal
            })
            const result = response.data.success.data;
            setTransactions(result)
            setProcessing(false);
        }

        getPayments();

        return () => {
            isMounted = false;
            controller.abort();
        }
    },[axiosPrivate]);

    const column = [
        {
            name: "Amount",
            selector: (row) => toCurrency(row?.amount),
        },
        {
            name: "Reference",
            selector: (row) => row.reference,
            sortable: true
        },
        {
            name: "Card Type",
            selector: (row) => row?.cardType,
            sortable: true
        },
        {
            name: "Card Number",
            selector: (row) => '******'+row?.cardNumber,
            sortable: true
        },
        {
            name: "Status",
            selector: (row) => <button className={`border-2 border-gray-100 p-1 rounded-full text-xs ${row.status === 'success' ? 'p-2 bg-green-600 border-white text-white' :  'bg-red-600 border-white p-2 text-white'}`}>{row.status}</button>
        },
        {
            name: "Date",
            selector: (row) => moment(row.createdAt).format('MMMM Do, YYYY h:mma')
        },
    ]

    return (
        <>
            <h2 className={"font-bold text-4xl"}>My Payments</h2>
                {
                    processing ? <div className={"flex justify-center items-center md:mt-[50%] md:ml-[90%]"}><Loader loading={processing} /></div>
                        :
                        <div className={"mt-[80px] flex flex-col justify-center relative md:w-fit w-full h-fit"}>
                            <DataTable
                                // title={'Transactions'}
                                columns={column}
                                data={transactions}
                                responsive={true}
                                pagination
                                fixedHeader
                                selectableRows
                                selectableRowsHighlight
                                highlightOnHover
                                fixedHeaderScrollHeight="800px"
                                progressPending={processing}
                            />
                        </div>
                }
        </>
    )
}

export default MyPayments;