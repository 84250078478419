import SkeletonThumbnail from "./SkeletonThumbnail";


const SkeletonRow = () => {

    return (
        <div className="space-y-0.5 md:space-y-2 mt-5">
            <div
                className="group relative md:-ml-2">
                <div
                    className="flex items-center space-x-0.5 overflow-x-scroll scrollbar-hide md:space-x-2.5 md:p-2"
                >
                    <SkeletonThumbnail />
                    <SkeletonThumbnail />
                    <SkeletonThumbnail />
                    <SkeletonThumbnail />
                    <SkeletonThumbnail />
                    <SkeletonThumbnail />
                    <SkeletonThumbnail />
                    <SkeletonThumbnail />
                    <SkeletonThumbnail />
                    <SkeletonThumbnail />
                    <SkeletonThumbnail />
                    <SkeletonThumbnail />
                </div>
            </div>
        </div>
    )
}

export default SkeletonRow;
