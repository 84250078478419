import {usePaystackPayment} from "react-paystack";
import { useState} from "react";
import {Box, Modal, Typography} from "@mui/material";

const PaystackPopUp = ({reference, email, amount, onSuccess, onClose, pk}) => {

    const [open, setOpen] = useState(true)

    const config = {
        reference,
        email,
        amount: amount* 100,
        publicKey: pk,
    };

    const initializePayment = usePaystackPayment(config);

    return (
        <Modal
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="flex flex-col justify-center items-center border-0"
        >
            <Box className={"absolute z-10 w-fit p-10 bg-black border-0"}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                   Are you sure you want to proceed with payment?
                </Typography>
                <div className={"flex justify-between mt-10"}>
                    <button
                        className={"bg-red-400 p-2 rounded"}
                        onClick={() => setOpen(false)}>Cancel</button>

                    <button
                        className={"bg-yellow-400 p-2 rounded"}
                        onClick={() => {
                        setOpen(false);
                        initializePayment(onSuccess, onClose)
                    }}>Proceed</button>


                </div>
            </Box>
        </Modal>
    )
}

export default PaystackPopUp;