import { SearchIcon } from '@heroicons/react/solid'

const Search = ({onFilterChange}) => {

    return (
        <div className="flex w-[350px] h-[40px]  border-gray-200 border-2 p-1 rounded-full items-center justify-center bg-transparent">
            <SearchIcon className="sm hidden h-5 w-5 sm:inline" />
            <input onChange={(e) => onFilterChange(e)} type="text" className="w-full p-2 bg-transparent rounded-full focus:outline-0 text-gray-200" placeholder="Search" />
        </div>
    )
}

export default Search;