import {Avatar} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LockResetIcon from '@mui/icons-material/LockReset';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import PaymentIcon from '@mui/icons-material/Payment';
import React from "react";
import LogoutIcon from "@mui/icons-material/Logout";
import useAuth from "../hooks/useAuth";
import MySubscription from "./MySubscription";
import MyProfile from "./MyProfile";
import UpdatePassword from "./UpdatePassword";
import axios from "../api/axios";
import {LOGOUT} from "../helpers/api-routes/v2";
import {useNavigate} from "react-router-dom";
import MyPayments from "./MyPayments";


const AccountCard = () => {


    const { auth, setAuth } = useAuth();

    const navigate = useNavigate();

    const [activeTab, setActiveTab] = React.useState(1);

    const DoLogout = async () => {

        setAuth({});

        await axios.get(LOGOUT, {withCredentials: true})

        return navigate('/auth/login', {replace: true});
    }


    return (
        <div className="h-full lg:m-[150px] sm:mt-[150px] shadow shadow-gray-600 text-white bg-black/70 grid lg:grid-cols-3 grid-cols-1">
            <div className={"border border-gray-500"}>
                <div className={"p-6 flex flex-col items-center mt-6"}>
                    {
                        auth?.user.image === null ? <Avatar sx={{ width: 100, height: 100 }}><h1 className={"font-bold text-6xl"}>{auth?.user.name.charAt(0)}</h1></Avatar> :
                        <Avatar
                            alt="Remy Sharp"
                            src="https://res.cloudinary.com/northflix/image/upload/v1659917965/actors/mddpognmqn6god3eazqj.jpg"
                            sx={{ width: 100, height: 100 }}
                        />
                    }

                    <h2 className={"mt-6 font-bold text-2xl"}>{auth?.user.name}</h2>
                </div>

                <div className={"flex flex-col items-center mt-[40px]"}>

                    <div onClick={() => setActiveTab(1)} className={`p-6 flex flex-row items-center border border-gray-500 block w-full justify-center ${activeTab === 1 ? 'bg-yellow-400 font-semibold' : ''} hover:bg-yellow-400 hover:border-0 cursor-pointer`}>
                        <AccountCircleIcon className={"h-6 w-6 mr-3"} /> <span className={"text-2xl"}>My Account</span>
                    </div>

                    <div  onClick={() => setActiveTab(2)} className={`p-6 flex flex-row items-center border border-gray-500 block w-full justify-center ${activeTab === 2 ? 'bg-yellow-400 font-semibold' : ''} hover:bg-yellow-400 hover:border-0 cursor-pointer`}>
                        <SubscriptionsIcon className={"h-4 w-4 mr-3"} /><span className={"text-2xl"}>My Subscription</span>
                    </div>

                    <div  onClick={() => setActiveTab(4)} className={`p-6 flex flex-row items-center border border-gray-500 block w-full justify-center ${activeTab === 4 ? 'bg-yellow-400 font-semibold' : ''} hover:bg-yellow-400 hover:border-0 cursor-pointer`}>
                        <PaymentIcon className={"h-4 w-4 mr-3"} /><span className={"text-2xl"}>My Payments</span>
                    </div>

                    <div  onClick={() => setActiveTab(3)} className={`p-6 flex flex-row items-center border border-gray-500 block w-full justify-center ${activeTab === 3 ? 'bg-yellow-400 font-semibold' : ''} hover:bg-yellow-400 hover:border-0 cursor-pointer`}>
                        <LockResetIcon className={"h-4 w-4 mr-3"} /><span className={"text-2xl"}>Change Password</span>
                    </div>

                    <div onClick={DoLogout} className={`p-6 flex flex-row items-center block w-full justify-center hover:border-0 cursor-pointer`}>
                        <LogoutIcon className={"h-4 w-4 mr-3"} /><span className={"text-2xl"}>Logout</span>
                    </div>

                </div>
            </div>

            <div className={"relative"}>
                <div className={"p-10"}>
                    {
                        activeTab === 1 ? <MyProfile /> : activeTab === 2 ? <MySubscription /> : activeTab === 3 ? <UpdatePassword /> : activeTab === 4 ? <MyPayments /> : null
                    }
                </div>
            </div>
        </div>
    )
}

export default AccountCard;