import {Route, Routes} from "react-router-dom";
import Layout from "./components/Layout";
import Login from "./pages/auth/Login";
import Signup from "./pages/auth/Signup";
import ForgetPassword from "./pages/auth/ForgetPassword";
import PersistLogin from "./components/PersistLogin";
import Container from "./components/Container";
import Home from "./pages/app/Home";
import Details from "./pages/app/Details";
import Search from "./pages/app/Search";
import Plans from "./pages/app/Plans";
import Account from "./pages/app/Account";
import GetMovies from "./components/GetMovies";

export default function App() {
  return (
      <Routes>
          <Route path="/" element={<Layout />}>
              {/* public routes */}
              <Route path="/" element={<Login />} />
              <Route path="/auth/login" element={<Login />} />
              <Route path="/auth/signup" element={<Signup />} />
              <Route path="/auth/forget-password" element={<ForgetPassword />} />
              {/* we want to protect these routes */}
              <Route element={<PersistLogin />}>
                  <Route path="/" element={<Container />}>
                      <Route element={<GetMovies />}>
                          <Route path="/app/home" element={<Home />} />
                          <Route path="/app/details/:id" element={<Details />} />
                          <Route path="/app/search" element={<Search />} />
                          <Route path="/app/plans" element={<Plans />} />
                          <Route path="/app/account" element={<Account/>} />
                      </Route>
                  </Route>
              </Route>
              {/*<Route element={<ForgetPassword />} />*/}
          </Route>
      </Routes>
  )
}