import React from "react";
import moment from "moment";
import useAuth from "../hooks/useAuth";
import {Loader} from "./Loader";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import {CANCEL_SUBSCRIPTION} from "../helpers/api-routes/v2";
import {toast} from 'react-toastify';

const MySubscription = () => {

    const { auth, setAuth } = useAuth();

    const [loading, setLoading] = React.useState(false);

    const axiosPrivate = useAxiosPrivate();

    const CancelSubscription = async () => {
        setLoading(true);
        const response = await axiosPrivate.post(`${CANCEL_SUBSCRIPTION}`);
        setLoading(false);
        if (response.status === 200) {
            toast.success("Subscription cancelled successfully");
            setAuth(prev => {
                return {
                    ...prev,
                    subscription: response?.data.success.data,
                }
            });
        }
        else
        {
            return toast.error(response.data.error.message);
        }
    }

    const CancelRequest = () => {
        let text = "Are you sure you want to cancel your subscription?";
        if (window.confirm(text) === true) {
            return CancelSubscription();
        }
    }

    return (
        <>
            <h2 className={"font-bold text-4xl"}>My Subscription</h2>
            <div className={"mt-[80px] flex flex-col justify-center"}>
                <div className={"flex items-center space-x-9"}>
                    <label className={"font-semibold text-2xl"}>Billing Email: </label>
                    <label className={""}>{auth?.subscription.email}</label>
                </div>

                <div className={"flex items-center space-x-9 mt-[40px]"}>
                    <label className={"font-semibold text-2xl"}>Subscription Date: </label>
                    <label className={""}>{moment(auth?.subscription?.subscriptionDate).format('Do MMMM YYYY')}</label>
                </div>

                <div className={"flex items-center space-x-9 mt-[40px]"}>
                    <label className={"font-semibold text-2xl"}>Expire On: </label>
                    <label className={""}>{moment(auth?.user?.isExpiredOn).format('Do MMMM YYYY')}</label>
                </div>

                <button
                    onClick={CancelRequest}
                    className={`w-full rounded bg-red-400 py-3 font-semibold mt-6 ${
                        loading && 'opacity-60'
                    }`}
                >
                    {loading ? (
                        <Loader color="dark:fill-gray-300" />
                    ) : (
                        'Cancel Subscription'
                    )}
                </button>

            </div>
        </>
    )
}

export default MySubscription