//App Origin
//export const origin = "http://192.168.1.187:3001";
export const origin = "https://northflix-api-production-24a0.up.railway.app";
//export const origin = "http://localhost:3001";

export const version = "/api/v4";

//Auth Routes
export const FORGET_PASSWORD = "/auth/web-forgot-password";
export const SIGN_UP = "/auth/register";
export const LOGIN             = "/auth/web-login";
export const REFRESH_TOKEN     = "/auth/re-authenticate";
export const LOGOUT             = "/auth/logout";

export const GET_PLANS = "/plans/listing";
export const GET_SETTINGS = "/contents//settings/fetch";
export const CREATE_INTENT = "/subscriptions/createIntent"
export const CONFIRM_PAYMENT = "/subscriptions/createSubscription"

export const GET_CATEGORIES = "/contents/category/listing";
export const GET_MOVIES = "/movie/find";

export const GET_BLOCK_IMAGES = "/contents/block/images"
export const GET_PAYMENTS = "/subscriptions/payments"
export const CANCEL_SUBSCRIPTION = "/subscriptions/cancel"
export const UPDATE_PASSWORD = "/user/update/password"
