import Search from "./Search";
import SearchResult from "./SearchResult";
import {useState} from "react";
import useAuth from "../hooks/useAuth";

const SearchBar = () => {

    const { movies } = useAuth();

    const [results, setResults] = useState([]);

    const [search, setSearch] = useState('');

    const onFilterChange = e => {
        const value = e.target.value;
        let filtered = movies.filter(m => m.title.toLowerCase().match(value.toLowerCase()));
        setResults(filtered);
        setSearch(value);
    }

    return (
        <div className={"m-20 flex justify-center items-center"}>
            <div className={"p-20 rounded:md"}>
                <div className={"flex justify-center items-center space-x-2"}>
                    <Search onFilterChange={onFilterChange} />
                </div>
                <SearchResult search={search} results={results} />
            </div>
        </div>
    )
}

export default SearchBar;