

const AppLoader = () => {

    return (
        <div className="flex justify-center items-center h-screen">
           <div className={"loader"}>
               <img
                   src={"https://res.cloudinary.com/northflix/image/upload/v1662914104/ic_launcher_mtrodg.png"}
                   alt={'loader'}
                   width={50}
                   height={50}
               />
           </div>
        </div>
    )
}

export default AppLoader;
