import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import useAuth from "../hooks/useAuth";
import {Link, useNavigate} from "react-router-dom";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import LogoutIcon from '@mui/icons-material/Logout';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import axios from "../api/axios";
import {LOGOUT} from "../helpers/api-routes/v2";

export default function DropDownMenu() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const navigate = useNavigate();

    const { setAuth } = useAuth();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const logout = async () => {
        setAnchorEl(null);
        setAuth({});

        await axios.get(LOGOUT, {withCredentials: true})

        return navigate('/auth/login', {replace: true});
    }

    return (
        <div>
            <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                <EmojiEmotionsIcon className={"h-6 w-6"} />
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem className={"p-2"}>
                    <Link to={'/app/account'} className={"flex justify-between items-center"}>
                       <AccountCircleIcon className={"h-4 w-4 mr-3"} /> <span className={"text-xs"}>My Account</span>
                    </Link>
                </MenuItem>
                {/*<MenuItem>*/}
                {/*    <Link to={'/app/help'} className={"flex justify-between items-center"}>*/}
                {/*        <LockResetIcon className={"h-4 w-4 mr-3"} /><span className={"text-xs"}>Change Password</span>*/}
                {/*    </Link>*/}
                {/*</MenuItem>*/}

                {/*<MenuItem>*/}
                {/*    <Link to={'/app/help'} className={"flex justify-between items-center"}>*/}
                {/*        <CancelIcon className={"h-4 w-4 mr-3"} /><span className={"text-xs"}>Cancel Subscription</span>*/}
                {/*    </Link>*/}
                {/*</MenuItem>*/}

                <MenuItem>
                    <Link to={'/app/help'} className={"flex justify-between items-center"}>
                        <HelpOutlineIcon className={"h-4 w-4 mr-3"} /><span className={"text-xs"}>Help Center</span>
                    </Link>
                </MenuItem>
                <MenuItem onClick={logout} className={"flex justify-between items-center"}>
                    <LogoutIcon className={"h-4 w-4 mr-3"} /><span className={"text-xs"}>Logout</span>
                </MenuItem>
            </Menu>
        </div>
    );
}
