import {Fragment} from "react";
import Header from "../../components/Header";
import useAuth from "../../hooks/useAuth";
import AppLoader from "../../components/AppLoader";
import AccountCard from "../../components/AccountCard";

const Account = () => {

    const { isLoading } = useAuth();

    return (
        <Fragment>
            {isLoading ? <AppLoader /> :
                <>
                    <Header />
                    <section className={'md:space-y-20'}>
                        <AccountCard />
                    </section>
                </>
            }
        </Fragment>
    )
}

export default Account;