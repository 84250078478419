import { useForm } from "react-hook-form";
import {useEffect, useState} from "react";
import {Loader} from "../../components/Loader";
import useAuth from '../../hooks/useAuth';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import axios from "../../api/axios";
import {LOGIN} from "../../helpers/api-routes/v2";
import {toast} from "react-toastify";

export default function Login() {

    const [loading, setLoading] = useState(false);

    const { setAuth, persist, setPersist } = useAuth();

    let navigate = useNavigate();

    const location = useLocation();

    const from = location.state?.from?.pathname || "/app/home";

    const { register, handleSubmit, formState: { errors } } = useForm();

    const onSubmit = async ({email, password}) => {

        setLoading(true);

        const payload = { email, password };

        try {
            const response = await axios.post(LOGIN,
                JSON.stringify(payload),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                }
            );

            setLoading(false);

            const result = response?.data?.success.data;

            const accessToken = result?.accessToken;

            const user = result?.user;

            localStorage.setItem('persist', persist);

            setPersist(true);

            setAuth({ user, accessToken, subscription: result?.subscription });

            navigate(from, { replace: true });

        } catch (err) {
            setLoading(false)
            console.log(err?.response?.data.error.message)
            toast.error(err?.response?.data.error.message);
        }

    };

    useEffect(() => {
        setPersist(true);
    },[setPersist]);

    return (
        <div className={`relative flex h-screen w-screen flex-col sm:bg-black/20 bg-black md:items-center md:justify-center md: bg-transparent`}>

            <div
                className={"w-full h-full bg-no-repeat bg-cover bg-left bg-fixed"}
                style={{
                backgroundImage: `url("https://res.cloudinary.com/dssguwmfu/image/upload/v1665382999/avatar/slider1_hx1t1n_wlqvwv.jpg")`
            }}>

                <img
                    src="https://res.cloudinary.com/dssguwmfu/image/upload/v1665383385/northflix_white_logo_1024_x_227_efp5f7_vbowbe_rkacuz.png"
                    className="absolute left-4 top-4 cursor-pointer object-contain md:left-10 md:top-6"
                    width={150}
                    height={150}
                    alt={'logo'}
                />
                <div className={"flex justify-center items-center md:mt-[160px]"}>
                    <form onSubmit={handleSubmit(onSubmit)} className={'relative mt-24 space-y-8 rounded bg-black/75 py-10 px-6 md:mt-0 md:max-w-md md:px-14'}>
                        <h1 className={"text-4xl font-semibold"}>Sign in</h1>
                        <div className={"space-y-4"}>
                            <label className={"inline-block w-full"}>
                                <input
                                    type={'email'}
                                    placeholder={'Email'}
                                    className={'input'}
                                    {...register("email",{required: true})}
                                />
                                {/* errors will return when field validation fails  */}
                                {errors.email && (
                                    <p className="p-1 text-[13px] font-light  text-orange-500">
                                        Please enter a valid email.
                                    </p>
                                )}
                            </label>
                            <label className={"inline-block w-full"}>
                                <input
                                    type={'password'}
                                    placeholder={'Password'}
                                    className={'input'}
                                    {...register("password", {required: true})}
                                />
                                {errors.password && (
                                    <p className="p-1 text-[13px] font-light  text-orange-500">
                                        Your password must contain between 6 and 60 characters.
                                    </p>
                                )}
                            </label>
                        </div>

                        <Link to={'/auth/forget-password'}>
                            <span className={"hover:underline text-white"}>Forget Password?</span>
                        </Link>

                        <button
                            className={`w-full rounded bg-yellow-500 py-3 font-semibold ${
                                loading && 'opacity-60'
                            }`}
                        >
                            {loading ? (
                                <Loader color="dark:fill-gray-300" />
                            ) : (
                                'Sign in'
                            )}
                        </button>

                        <div className={"text-[gray]"}>
                            New to Northflix? {' '}
                            <Link to={'/auth/signup'}>
                                <span className={"hover:underline text-white"}>Sign up</span>
                            </Link>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
