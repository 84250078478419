import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import FavoriteIcon from '@mui/icons-material/Favorite';
import StarRateIcon from '@mui/icons-material/StarRate';
import {PlayIcon} from "@heroicons/react/solid";
import Cast from "./Cast";
import {Divider} from "@mui/material";
import SeriesCard from "./SeriesCard";
import ShareIcon from '@mui/icons-material/Share';
import Reviews from "./Reviews";
import {useState, useEffect} from "react";
import Modal from "./Modal";
import useAuth from "../hooks/useAuth";


const MovieCard = ({movieId}) => {

    const { movies } = useAuth();

    const [movie, setMovie] = useState(null);

    useEffect( () => {
        const current = movies.find(m => m.id === movieId)
        setMovie(current);
    },[movies, movieId]);

    const [showModal, setShowModal] = useState(false);
    const [streamType, setStreamType] = useState('');
    const [trailerUri, setTrailerUri] = useState(null);

    const controlModal = (modalState, streamType, trailerUri) => {
        setShowModal(modalState);
        setStreamType(streamType);
        setTrailerUri(trailerUri);
    }

    return (
        <div className={'bg-white mt-[90px] overflow-hidden'}>
               <div className={'flex lg:flex-row flex-col bg-black w-full text-white p-10 gap-x-10'}>
                   <div className={`relative md:h-[450px] md:min-w-[300px]`}>
                       <img
                           src={movie?.posterUri}
                           className="rounded-sm object-cover md:rounded md:w-[300px] md:h-[450px] w-[200px] h-[350px]"
                           alt="fill"
                       />
                   </div>

                   <div className={"flex justify-center mt-4 "}>
                       <div className={"w-full flex flex-col justify-center items-start"}>
                           <h1 className={"text-3xl font-semibold"}>{movie?.title.toUpperCase()} ({movie?.year})</h1>
                           <ul className={"mt-5"}>
                               <li>
                                   {movie?.genre.length > 0 && movie?.genre.map((g) => ( <span className={"text-sm font-semibold"}>{g?.name}, </span>))}
                               </li>
                           </ul>

                           <div className={"flex items-center space-x-4 mt-5"}>
                               <div className={"bg-yellow-600 rounded-full w-[30px] h-[30px] md:p-6 p-4 flex justify-center items-center cursor-pointer"}>
                                   <FormatListNumberedIcon className={"h-5 w-5 "} />
                               </div>
                               <div className={"bg-yellow-600 rounded-full w-[30px] h-[30px] md:p-6 p-4 flex justify-center items-center cursor-pointer"}>
                                   <ShareIcon className={"h-5 w-5 "} />
                               </div>

                               <div className={"bg-yellow-600 rounded-full w-[30px] h-[30px] md:p-6 p-4 flex justify-center items-center cursor-pointer"}>
                                   <FavoriteIcon className={"h-5 w-5 "} />
                               </div>

                               <div className={"bg-yellow-600 rounded-full w-[30px] h-[30px] md:p-6 p-4 flex justify-center items-center cursor-pointer"}>
                                   <StarRateIcon className={"h-5 w-5 "} />
                               </div>
                           </div>

                           <div className={"mt-4 flex space-x-6"}>
                               <button
                                   onClick={() => controlModal(true,'movie', null)}
                                   className={" flex items-center justify-center space-x-2 bg-yellow-400 p-3 rounded"}>
                                   <PlayIcon className={"h-3 w-3 md:h-5 md:w-5"} />
                                   <span className={"text-sm font-bold"}>Play Movie</span>
                               </button>

                               <button
                                   onClick={() => controlModal(true,'trailer', movie?.trailerUri)}
                                   className={"flex items-center justify-center space-x-2 bg-white/75 p-3 rounded"}>
                                   <PlayIcon className={"h-3 w-3 md:h-5 md:w-5"} />
                                   <span className={"text-sm font-bold"}>Play Trailer</span>
                               </button>
                           </div>

                           <h1 className={"font-semibold mt-5"}>Overview</h1>
                           <div className={"mt-2"}>
                               <span className={"md:w-[600px] w-auto"}>{movie?.description}</span>
                           </div>

                           <h1 className={"font-semibold mt-5"}>Exclusive On:</h1>
                           <div className={"mt-2 w-[600px]"}>
                               <span>Northflix Nigeria Limited</span>
                           </div>
                       </div>
                   </div>
               </div>


            {movie?.actors.length > 0 && <Cast actors={movie?.actors} />}
            <Divider />
            {
                movie?.streamType === 'series' &&
                <SeriesCard movie={movie} />
            }
            <Divider />
            {movie?.reviews.length > 0 && <Reviews reviews={movie?.reviews} /> }
            {
                showModal && <Modal showModal={showModal} setShowModal={setShowModal} streamType={streamType} trailerUri={trailerUri} />
            }
        </div>
    )
}

export default MovieCard;