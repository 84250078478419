import axios from 'axios';
import { version, origin } from '../helpers/api-routes/v2'

const BASE_URL = origin+version;

export default axios.create({
    baseURL: BASE_URL
});

export const axiosPrivate = axios.create({
    baseURL: BASE_URL,
    headers: { 'Content-Type': 'application/json' },
    withCredentials: true
});