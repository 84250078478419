import Thumbnail from "./Thumbnail";

const SearchResult = ({search, results}) => {
    return (
        <>
           <div className={"flex justify-center items-center mt-4 overflow-hidden"}>
               {
                   search.length > 0 &&  <h1 className={"text-gray-400"}>{results.length} Search Result for : {search}</h1>
               }
           </div>
            <div className={"mt-20 grid md:grid-cols-4 md:gap-6 lg:grid-cols-6 lg:gap-6 sm:grid-cols-2 sm:gap-4 grid-cols-2 gap-4 h-[125px] min-w-[100px] sm:h-[225px] sm:min-w-[150px]"}>
                {results.map(result => (
                    <Thumbnail key={result.id} movie={result} />
                ))}
            </div>
        </>

    )
}

export default SearchResult;