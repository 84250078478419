import {Outlet} from "react-router-dom";
import useAuth from "../hooks/useAuth";
import {useEffect} from "react";
import { useNavigate } from 'react-router-dom';
import moment from "moment";


const Container = () => {

    let navigate = useNavigate();

    const { auth } = useAuth();

    useEffect(() => {
        const subscription = auth?.subscription;
        if (subscription === undefined) {
            navigate('/app/plans',{replace: true});
        }
        else if (moment().format('YYYY-MM-DD') > subscription?.isExpiredOn || !subscription?.isSubscribed) {
            navigate('/app/plans',{replace: true});
        }
    },[navigate, auth]);

    return (
        <div className={`relative h-screen bg-gradient-to-b from-gray-900/10 to-[#010511] lg:h-[140vh]`}>
            <Outlet />
        </div>
    )
}

export default Container;