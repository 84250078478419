import axios from '../api/axios';
import useAuth from './useAuth';
import {REFRESH_TOKEN} from "../helpers/api-routes/v2";

const useRefreshToken = () => {
    const { setAuth } = useAuth();

    return async () => {
        const response = await axios.get(REFRESH_TOKEN, {
            withCredentials: true
        });
        setAuth(prev => {
            return {
                ...prev,
                user: response.data?.success?.data.user,
                accessToken: response.data?.success?.data.accessToken,
                subscription: response.data?.success?.data.subscription,
            }
        });
        return response.data?.success?.data.accessToken;
    };
};

export default useRefreshToken;
