import {Link} from "react-router-dom";
import { useForm } from "react-hook-form";
import {Loader} from "../../components/Loader";
import {useState} from "react";
import axios from "../../api/axios";
import {FORGET_PASSWORD} from "../../helpers/api-routes/v2";
import {toast} from "react-toastify";

export default function ForgetPassword() {

    const [loading, setLoading] = useState(false);

    const { register, handleSubmit, formState: { errors } } = useForm();
    const onSubmit = async ({email}) => {
        setLoading(true);
        const payload = { email };
        try {
            const response = await axios.post(FORGET_PASSWORD,
                JSON.stringify(payload),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                }
            );

            setLoading(false);
            const result = response?.data?.success;
            toast.success(result.message);
        } catch (err) {
            setLoading(false)
            console.log(err?.response?.data.error.message)
            toast.error(err?.response?.data.error.message);
        }
    };

    return (
        <div className={`relative flex h-screen w-screen flex-col sm:bg-black/20 bg-black md:items-center md:justify-center md: bg-transparent`}>

            <div
                className={"w-full h-full bg-no-repeat bg-cover bg-left bg-fixed"}
                style={{
                    backgroundImage: `url("https://res.cloudinary.com/northflix/image/upload/v1650898997/avatar/bg_1_dwoxjs.jpg")`
                }}>

                <img
                    src="https://res.cloudinary.com/northflix/image/upload/v1650827688/avatar/northflix_white_logo_1024_x_227_efp5f7.svg"
                    className="absolute left-4 top-4 cursor-pointer object-contain md:left-10 md:top-6"
                    width={150}
                    height={150}
                    alt={'logo'}
                />
                <div className={"flex justify-center items-center md:mt-[160px]"}>
                    <form onSubmit={handleSubmit(onSubmit)} className={'relative mt-24 space-y-8 rounded bg-black/75 py-10 px-6 md:mt-0 md:max-w-md md:px-14'}>
                        <h1 className={"text-4xl font-semibold"}>Forget Password</h1>
                        <div className={"space-y-4"}>
                            <label className={"inline-block w-full"}>
                                <input
                                    type={'email'}
                                    placeholder={'Email'}
                                    className={'input'}
                                    {...register("email",{required: true})}
                                />
                                {/* errors will return when field validation fails  */}
                                {errors.email && (
                                    <p className="p-1 text-[13px] font-light  text-orange-500">
                                        Please enter a valid email.
                                    </p>
                                )}
                            </label>
                        </div>

                        <button
                            type={'submit'}
                            className={`w-full rounded bg-yellow-500 py-3 font-semibold ${
                                loading && 'opacity-60'
                            }`}
                        >
                            {loading ? (
                                <Loader color="dark:fill-gray-300" />
                            ) : (
                                'Reset Password'
                            )}
                        </button>

                        <div className={"text-[gray]"}>
                            New to Northflix? {' '}
                            <Link to={'/auth/signup'}>
                                <span className={"hover:underline text-white"}>Sign up</span>
                            </Link>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}