

const Reviews = ({reviews}) => {
    return (
        <div className={"m-5 text-black"}>
            <h1 className={"font-bold"}>Reviews</h1>

            {
                reviews.length > 0 ? reviews.map((review) => (
                    <div className={"flex flex-col justify-center shadow p-10 mt-3 pb-10"}>
                        <h1 className={"font-semibold"}>Unknown</h1>
                        <span className={"text-gray-300"}>{review?.date}</span>
                        <span className={"text-sm mt-2"}>{review?.review}</span>
                    </div>
                ))
                    :
                    <div className={"flex justify-center items-center"}>
                        <span className={"font-bold"}>No review</span>
                    </div>
            }
        </div>
    )
}

export default Reviews;