import {useEffect, useState} from "react";

const Cast = ({actors}) => {

    const [actorsList, setActorsList] = useState([]);

    useEffect(() => {
        setActorsList(actors);
    },[actors, actorsList]);

    return (
        <div className={"mt-5 m-5 text-black"}>
            <h1 className={"font-semibold text-2xl"}>
                Movie Cast
            </h1>
            <div className="flex flex-wrap mt-4 text-black">
                {
                    actorsList.map((actor) => (
                        <div key={actor.id} className={"flex flex-col justify-center  p-2"}>
                            <img
                                src={actor?.poster}
                                className="rounded-sm object-cover md:rounded md:w-[138px] md:h-[230px] w-[100px] h-[150px]"
                                alt="fill"
                            />
                            <span className={"font-semibold mt-2"}>{actor?.name.length > 15 ? actor?.name.substring(0, 15)+'...' : actor?.name}</span>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default Cast;