import React from "react";
import {ClipLoader} from "react-spinners";

export function Loader ({loading}) {
    const color = "#FFFFFF";
    return (
        <ClipLoader
            color={color}
            loading={loading}
            size={18}
        />
    )
}