import {CheckIcon} from "@heroicons/react/solid";
import {Link, useLocation, useNavigate} from "react-router-dom";
import axios from "../../api/axios";
import {LOGOUT, GET_PLANS, CREATE_INTENT, CONFIRM_PAYMENT, GET_SETTINGS} from "../../helpers/api-routes/v2";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import {useEffect, useState} from "react";
import useAuth from "../../hooks/useAuth";
import {toast} from "react-toastify";
import PaystackPopUp from "../../components/PaystackPopUp";
import moment from "moment";
import AppLoader from "../../components/AppLoader";

const Plans = () => {

    const { setAuth, auth } = useAuth();

    const [loading, setLoading] = useState(false);

    const [pk, setpK] = useState(null);

    const [paystackModal, setPaystackModal] = useState(false);

    const [plans, setPlans] = useState([]);

    const [selected, setSelected] = useState(null);

    const axiosPrivate = useAxiosPrivate();

    const navigate = useNavigate();

    const location = useLocation();

    const [reference, setReference] = useState(null);

    useEffect(() => {

        const subscription = auth?.subscription;
        if (subscription && moment().format('YYYY-MM-DD') < subscription?.isExpiredOn) {
            return navigate('/app/home',{replace: true});
        }

        let isMounted = true;

        const controller = new AbortController();

        const getPlans = async () => {
           try{
               const response = await axiosPrivate.get(GET_PLANS, {
                   signal: controller.signal
               });
               isMounted && setPlans(response.data.success.data);
           }
           catch (e) {
               console.log(e);
           }
        }

        const getSettings = async () => {
            try{
                const response = await axiosPrivate.get(GET_SETTINGS, {
                    signal: controller.signal
                });
                console.log(response);
                isMounted && setpK(response?.data.success?.data?.payStackKey);
            }
            catch (e) {
                console.log(e);
            }
        }

        getSettings();
        getPlans();

        return () => {
            isMounted = false;
            controller.abort();
        }

    },[axiosPrivate, location, navigate, auth?.subscription]);

    // you can call this function anything
    const onSuccess = (response) => {
        if (!auth?.user) return
        setLoading(true);
        if (response.status === 'success') {
            const payload = {
                reference,
                paymentMethod: 'paystack',
                cardId: null,
                applePay: {
                    token: null
                },
            }
            confirmPayment(payload).then((result) => {
                setAuth(prev => {
                    return {
                        ...prev,
                        subscription: result.data?.success?.data,
                    }
                });
                toast.success('Transaction was successful');
                navigate('/app/home', {replace: true});
            }).catch((e) => {
                setLoading(false);
                toast.error(e?.response.data.error.message);
            })
        }
        console.log('failed')
    };

    const onClose = () => {
        console.log('closed')
    }

    const planSelected = plan => {
        setSelected(plan);
        return createPaymentIntent(plan)
    }

    const createPaymentIntent = async (plan) => {
        const ref = (new Date()).getTime().toString()
        setReference(ref);
        try {
            const payload = {
                planId: plan?.planId,
                amount: plan?.amount,
                currency: 'NGN',
                paymentMethod: 'paystack',
                reference: ref,
                couponId:null,
                cardId: null,
            }
            setLoading(true);
            await createIntentAction(payload);

            setPaystackModal(true);
        }
        catch (e) {
            setLoading(false);
            toast.error(e?.response?.data?.error?.message)
        }
    }

    const createIntentAction = async (payload) => {
        axiosPrivate.post(CREATE_INTENT,payload).then((response) => {
            setLoading(false);
        }).catch((e) => {
          return toast.error(e?.response?.data?.error?.message)
        })
    }

    const confirmPayment = async (payload) => {
        return axiosPrivate.post(CONFIRM_PAYMENT,payload)
    }

    const onLogout = async () => {

        setAuth({});

        await axios.get(LOGOUT, {withCredentials: true})

        return navigate('/auth/login', {replace: true});
    }

    return (
        <>
            { loading ? <AppLoader /> :
                <>
                    <div className="plans">
                        <header className="border-b border-white/10 bg-[#141414]">
                            <Link to="/">
                                <img
                                    src="https://res.cloudinary.com/northflix/image/upload/v1650827688/avatar/northflix_white_logo_1024_x_227_efp5f7.svg"
                                    width={150}
                                    height={150}
                                    className="cursor-pointer object-contain"
                                    alt={"Northflix"}
                                />
                            </Link>
                            <button
                                onClick={onLogout}
                                className="text-lg font-medium hover:underline"
                            >
                                Sign Out
                            </button>
                        </header>

                        <main className="mx-auto max-w-5xl px-5 pt-28 pb-12 transition-all md:px-10">
                            <h1 className="mb-3 text-3xl font-medium">
                                Purchase Memberships
                            </h1>
                            <ul>
                                <li className="flex items-center gap-x-2 text-lg">
                                    <CheckIcon className="h-7 w-7 text-yellow-500"/> Get Access to All full HD contents.
                                </li>
                                <li className="flex items-center gap-x-2 text-lg">
                                    <CheckIcon className="h-7 w-7 text-yellow-500"/> Enable Download Movie.
                                </li>
                                <li className="flex items-center gap-x-2 text-lg">
                                    <CheckIcon className="h-7 w-7 text-yellow-500"/> Watch Premium Content.
                                </li>
                            </ul>

                            <div className="mt-4 flex flex-col space-y-4">
                                {
                                    plans.length > 0 &&
                                    plans.map((plan) => {
                                        return (
                                            <div onClick={() => planSelected(plan)} key={plan._id} className="flex items-center justify-center w-[100%] cursor-pointer">
                                                <img
                                                    src={plan?.image}
                                                    alt={"plans"}
                                                    className={"w-screen"}
                                                />
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </main>
                    </div>
                    { paystackModal && <PaystackPopUp pk={pk} amount={selected?.amount} reference={reference} email={auth?.user.email} onClose={onClose} onSuccess={onSuccess} /> }
                </>
            }

        </>
    )
}

export default Plans;