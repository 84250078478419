import { useEffect, useState} from 'react'
import DropDownMenu from "./DropDown";
import {SearchIcon} from "@heroicons/react/solid";
import {Link} from "react-router-dom";
import useAuth from "../hooks/useAuth";
import MobileMenu from "./MobileMenu";

function Header({setFilteredCategory}) {

    const { moviesCategories } = useAuth();

    const pathname = window.location.pathname

    const [current, setCurrent] = useState('home');

    const [isScrolled, setIsScrolled] = useState(false)
    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setIsScrolled(true)
            } else {
                setIsScrolled(false)
            }
        }

        window.addEventListener('scroll', handleScroll)

        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    const changeCategory = (category) => {
        setCurrent(category)
        if (category === 'home'){
            setFilteredCategory(moviesCategories);
        }else {
            setFilteredCategory(moviesCategories.filter(c => c.name === category))
        }
    }

    return (
        <header className={`${isScrolled && 'bg-[#141414]'}`}>
            <div className="flex items-center space-x-2 md:space-x-10">
                <img
                    src="https://res.cloudinary.com/dssguwmfu/image/upload/v1665383385/northflix_white_logo_1024_x_227_efp5f7_vbowbe_rkacuz.png"
                    width={120}
                    height={120}
                    className="cursor-pointer object-contain"
                    alt={"northflix"}
                />

               <MobileMenu setFilteredCategory={setFilteredCategory} />

                <ul className="hidden space-x-6 md:flex">
                    <li onClick={() => changeCategory('home')} className={`headerLink cursor-pointer ${current === 'home' ? 'font-semibold' : ''} text-white hover:text-white`}>
                        <Link to={"/app/home"}>Home</Link>
                    </li>

                    {
                        pathname === '/app/home' &&
                            moviesCategories.map((category) => (
                                <li onClick={() => changeCategory(category?.name)} className={`headerLink cursor-pointer ${current.toLowerCase() === category?.name.toLowerCase() ? 'font-semibold' : ''} text-white hover:text-white`}>
                                    <Link to={"/app/home"}>{category?.name}</Link>
                                </li>
                            ))
                    }
                </ul>
            </div>

            <div className="flex items-center space-x-4 text-sm font-light">
                <Link to={"/app/search"}>
                    <SearchIcon className="sm hidden h-6 w-6 sm:inline" />
                </Link>
                <DropDownMenu />
            </div>
        </header>
    )
}

export default Header
