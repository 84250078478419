import {Link} from "react-router-dom";

function Thumbnail({movie}) {
    return (
        <Link to={"/app/details/"+movie?.id} className={`relative cursor-pointer transition duration-200 ease-out h-[125px] min-w-[100px] sm:h-[225px] sm:min-w-[150px] md:hover:scale-105`}>
            <img
                src={movie?.posterUri}
                className="rounded-sm object-cover md:rounded h-[125px] min-w-[100px] sm:w-[150px] sm:h-[225px]"
                alt="fill"
                width={'100%'}
                height={'100%'}
            />
        </Link>
    )
}

export default Thumbnail
