import React, {useEffect, useState} from "react";
import useAuth from "../hooks/useAuth";


const Banner = () => {

    const { blockImages } = useAuth();

    const [block, setBlock] = useState([]);

    useEffect(() => {
        setBlock(blockImages[Math.floor(Math.random() * blockImages.length)]);
        setBlock(blockImages[0])
    },[blockImages]);


    return (
        <div className="flex flex-col space-y-2 py-16 md:space-y-4 lg:h-[55vh] lg:justify-end lg:pb-12">
            <div className="absolute top-0 left-0 -z-10 h-[75vh] w-screen">
                <img
                    src={`${block?.uri}`}
                    alt={"title"}
                    style={{
                       width: "100%",
                       height: "100%",
                    }}
                />
            </div>

            {/*<h1 className={'text-2xl lg:text-7xl md:text-4xl font-bold'}>*/}
            {/*    Musa Gabi*/}
            {/*</h1>*/}
            {/*<p className={"max-w-xs text-shadow-md text-xs md:max-w-lg md:text-lg lg:max-w-2xl lg:font-2xl"}>*/}
            {/*    Musa Gabi is a young and beautiful*/}
            {/*</p>*/}

            {/*<div className={"flex space-x-3"}>*/}
            {/*    <Link to={"/app/details"} className={"bannerButton bg-[#ffc846]"}>*/}
            {/*        <InformationCircleIcon className={"h-4 w-4 md:h-8 md:w-8"} />*/}
            {/*        More Info*/}
            {/*    </Link>*/}
            {/*</div>*/}
        </div>
    )
}

export default Banner;
