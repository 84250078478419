import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import {Link} from "react-router-dom";

const Footer = () => {
    return (
        <div className={"flex flex-col justify-center items-center bg-black w-full p-10"}>
            <div className={"flex justify-center items-center space-x-4"}>
                <a href={"https://web.facebook.com/Northflixnig"} target={"_blank"} rel={"noreferrer"}>
                    <FacebookIcon className={"h-6 w-6 rounded-full cursor-pointer"} />
                </a>

                <a href={"https://www.instagram.com/northflixng/"} target={"_blank"} rel={"noreferrer"}>
                    <InstagramIcon className={"h-6 w-6 rounded-full cursor-pointer"} />
                </a>

                <a href={"https://www.youtube.com/channel/UCfvEIMY4209jgdbWCy7n2_g"} target={"_blank"} rel={"noreferrer"}>
                    <YouTubeIcon className={"h-6 w-6 rounded-full cursor-pointer"} />
                </a>

                <a href={"https://www.linkedin.com/company/northflix/"} target={"_blank"} rel={"noreferrer"}>
                    <LinkedInIcon className={"h-6 w-6 rounded-full cursor-pointer"} />
                </a>
            </div>

            <div className={"flex justify-center items-center mt-10"}>
                <ul>
                    <li className={"space-x-10 text-gray-400 text-xs"}>

                        <Link to={"/action"} className={"hover:text-white"}>
                            <span>Privacy Policy</span>
                        </Link>

                        <Link to={"/action"} className={"hover:text-white"}>
                            <span>Contact Us</span>
                        </Link>

                        <Link to={"/action"} className={"hover:text-white"}>
                            <span>Terms of Use</span>
                        </Link>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default Footer;