import {useState} from "react";

const SeriesCard = ({movie}) => {

    const [selectedSeason, setSelectedSeason] = useState(movie?.seasons[0]?.seasonId);

    return (
        <>
            <div className="flex justify-between m-5 text-black">
                <h1 className="text-2xl font-bold">Episodes</h1>
                <select className={"rounded:md"} onChange={(e) => setSelectedSeason(e.target.value)}>
                    {movie?.seasons.map(season => (
                        <option key={season?.id} value={season?.seasonId}>Season {season?.season}</option>
                    ))}
                </select>
            </div>

            {
                movie?.seasons.map(season => {
                    if (season?.seasonId === selectedSeason) {
                        return (
                            season?.episodes.episodes.map(episode => (
                                <div key={episode?._id} className={"m-5 flex items-center p-10 bg-gray-200 text-black"}>
                                    <img
                                        src={`${movie?.posterUri}`}
                                        className={"w-[150px] h-[150px] rounded-sm object-cover md:rounded"}
                                        alt={"poster"}
                                    />
                                    <div className={"m-5 w-full"}>
                                        <div className={"flex justify-between"}>
                                            <h1 className={"font-semibold"}>Episode {episode?.episodeNumber}</h1>
                                            <span>{episode?.episodeDuration}</span>
                                        </div>
                                        <span>{episode?.episodeTitle}</span>
                                    </div>
                                </div>
                            ))
                        )
                    }
                })
            }
        </>
    )
}

export default SeriesCard;